import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/atoms/seo";
import { LIBRARY_COLOR } from "../theme/default";
import LibraryTabs from "../components/organisms/libraryTabs";
import { withTranslation } from "react-i18next";

class Library extends React.Component {
  render() {
    return (
      <Layout color={LIBRARY_COLOR}>
        <Seo
          title={this.props.t("Technical Library")}
          keywords={[`PerkinElmer`]}
        />
        <LibraryTabs></LibraryTabs>
      </Layout>
    );
  }
}

export default withTranslation()(Library);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "library"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
